import { ChangeEvent, KeyboardEvent, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete from "use-places-autocomplete";

let cachedVal = "";
const acceptedKeys = ["ArrowUp", "ArrowDown", "Escape", "Enter"];

type Suggestion = google.maps.places.AutocompletePrediction;

const UsePlaceAutocompleteElement = (props: any): JSX.Element => {
  const { id, placeholder } = props;
  const [currIndex, setCurrIndex] = useState<number | null>(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'vn' },
      offset: 3
    },
    debounce: 500,
  });
  const hasSuggestions = status === "OK";

  const setAttr = (name: string, state: string) => {
    document.getElementById(id)?.setAttribute(name, state);
    document.getElementById(id)?.parentElement?.setAttribute(name, state);
  }

  const dismissSuggestions = () => {
    setCurrIndex(null);
    clearSuggestions();
  };

  const ref = useOnclickOutside(dismissSuggestions);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    cachedVal = e.target.value;
  };

  const handleClick = (e: any) => {
    setValue(e.target.value, false);
    cachedVal = e.target.value;
  }

  const handleFocus = (e: any) => {
    e.target.select();
  }

  const handleSelect =
    ({ description, place_id }: Suggestion) =>
      () => {
        setValue(description, false);
        setAttr('state', 'done');
        setAttr('place_id', place_id);
        dismissSuggestions();
      };

  const handleEnter = (idx: number) => () => {
    setCurrIndex(idx);
  };

  const handleLeave = () => {
    setCurrIndex(null);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setAttr('state', '');
      setAttr('place_id', '');
    }

    if (!hasSuggestions || !acceptedKeys.includes(e.key)) return;

    if (e.key === "Enter") {
      if (currIndex != null) {
        setAttr('state', 'done');
        setAttr('place_id', data[currIndex].place_id);
        setValue(data[currIndex] ? data[currIndex].description : cachedVal, false);
        dismissSuggestions();
        return;
      }
    }

    if (e.key === "Escape") {
      dismissSuggestions();
      return;
    }

    setAttr('state', '');
    setAttr('place_id', '');

    let nextIndex: number | null;

    if (e.key === "ArrowUp") {
      e.preventDefault();
      nextIndex = currIndex ?? data.length;
      nextIndex = nextIndex > 0 ? nextIndex - 1 : null;
    } else {
      nextIndex = currIndex ?? -1;
      nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
    }

    setCurrIndex(nextIndex);
    // @ts-expect-error
    setValue(data[nextIndex] ? data[nextIndex].description : cachedVal, false);
    if (nextIndex != null && data[nextIndex]) {
      setAttr('state', 'done');
      setAttr('place_id', data[nextIndex].place_id);
    }
  };

  const renderSuggestions = (): JSX.Element => {
    const suggestions = data.map((suggestion: Suggestion, idx: number) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={place_id}
          id={`ex-list-item-${idx}`}
          className={idx === currIndex ? "active" : ""}
          onClick={handleSelect(suggestion)}
          onMouseEnter={handleEnter(idx)}
          role="option"
          aria-selected={idx === currIndex}
        >
          {main_text} {secondary_text}
        </li>
      );
    });

    return (
      <>
        {suggestions}
        <li className="powered-by-google">
          <img
            src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png"
            alt="Powered by Google"
          />
        </li>
      </>
    );
  };

  return (
    <>
      <div
        className="autocomplete"
        ref={ref}
        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
        role="combobox"
        aria-owns="ex-list-box"
        aria-haspopup="listbox"
        aria-expanded={hasSuggestions}
      >
        <input
          id={id ?? ''}
          className="form-control autocomplete-control"
          value={value}
          onFocus={handleFocus}
          onClick={handleClick}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          disabled={!ready}
          placeholder={placeholder}
          type="text"
          aria-autocomplete="list"
          aria-controls="ex-list-box"
          aria-activedescendant={
            currIndex !== null ? `ex-list-item-${currIndex}` : undefined
          }
          autoComplete="off"
          required
        />
        {hasSuggestions && (
          <ul
            id="ex-list-box"
            className="autocomplete-items"
            onMouseLeave={handleLeave}
            role="listbox"
          >
            {renderSuggestions()}
          </ul>
        )}
      </div>
    </>
  );
};

export default UsePlaceAutocompleteElement;
