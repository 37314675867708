import { StrictMode } from "react";
import { render } from "react-dom";

import UsePlaceAutocompleteElement from "./UsePlaceAutocompleteElement";

render(
  <StrictMode>
    <div className="form-group">
      <label htmlFor="from-address">Điểm đi</label>
      <UsePlaceAutocompleteElement id="from-address" placeholder="Nhập điểm đi" />
    </div>
    <div className="form-group">
      <label htmlFor="to-address">Điểm đến</label>
      <UsePlaceAutocompleteElement id="to-address" placeholder="Nhập điểm đến" />
    </div>
  </StrictMode>,
  document.getElementById("use-place-autocomplete-widget")
);

render(
  <StrictMode>
    <div className="form-group">
      <label htmlFor="from-address">Điểm đi</label>
      <UsePlaceAutocompleteElement id="from-address2" placeholder="Nhập điểm đi" />
    </div>
    <div className="form-group">
      <label htmlFor="to-address">Điểm đến</label>
      <UsePlaceAutocompleteElement id="to-address2" placeholder="Nhập điểm đến" />
    </div>
  </StrictMode>,
  document.getElementById("use-place-autocomplete-widget2")
);
